










































































































































import { Component, Vue } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';
import VDropdown from '@/components/VDropdown.vue';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/account/types';
import FormField from '@/utilities/validation/formfield';
import ValidatedForm from '@/utilities/validation/validatedform';
import emailValidator from '@/utilities/validators/email';
import { SubscriptionUser } from '@/interfaces/subscriptionUser';
import UserInfo from '@/interfaces/userInfo';
import { AxiosResponse } from 'axios';

const namespace: string = 'account';

@Component({ components: { VButton, VDropdown } })
export default class VAddUsers extends Vue {
  @Action('subscriptionUsers', { namespace }) private getSubscriptionInfo!: types.SubscriptionUsersAction;
  @Action('addSubscriptionUser', { namespace }) private addSubscriptionUser!: types.AddSubscriptionUserAction;
  @Action('deleteSubscriptionUser', { namespace }) private deleteSubscriptionUser!: types.DeleteSubscriptionUserAction;
  @Action('editUserRole', { namespace }) private editUserRole!: types.EditUserRoleAction;
  @Getter('userInfo', { namespace }) private userInfo!: UserInfo;

  private addingSubscriptionUser = false;
  private deletingSubscriptionUser = false;
  private editingUserRole = '';
  private deleteUserEmail = '';
  private subscriptionUsers: SubscriptionUser[] = new Array<SubscriptionUser>();

  private showServerError = false;
  private showUserAdded = false;
  private serverError = '';
  private sortColumn = 'email';

  private newUserForm: ValidatedForm = new ValidatedForm({
    email: new FormField(emailValidator, ''),
    role: new FormField(() => true, 'Gebruiker'),
  });

  get newUserFormValid() { return this.newUserForm.fieldValidity(); }

  private created(): void {
    this.getSubscriptionInfo().then((response) => {
      this.subscriptionUsers = response;
    });
  }

  private submitAddSubscriptionUser() {
    this.showServerError = false;
    this.showUserAdded = false;
    if (this.newUserForm.formValid()) {
      this.addingSubscriptionUser = true;
      this.addSubscriptionUser({
        email: this.newUserForm.fields.email.value,
        username: this.newUserForm.fields.email.value,
        role: this.newUserForm.fields.role.value,
      }).then(() => {
        this.subscriptionUsers.push({
          email: this.newUserForm.fields.email.value,
          role: this.newUserForm.fields.role.value,
          first_name: '',
          last_name: '',
        });
        this.newUserForm.fields.email.value = '';
        this.newUserForm.fields.email.dirty = false;
        this.showUserAdded = true;
      }).catch((response: AxiosResponse) => {
        this.serverError = response.data[0];
        this.showServerError = true;
      }).finally(() => {
        this.addingSubscriptionUser = false;
      });
    }
  }

  private onClickDeleteSubscriptionUser(email: string) {
    this.deleteUserEmail = email;
    (this.$refs['delete-subuser-modal'] as any).show();
  }

  private submitDeleteSubscriptionUser(email: string) {
    this.deletingSubscriptionUser = true;
    this.deleteSubscriptionUser({ email }).then(() => {
      this.subscriptionUsers = this.subscriptionUsers.filter((user) => user.email !== email);
    }).finally(() => {
      this.deletingSubscriptionUser = false;
      (this.$refs['delete-subuser-modal'] as any).hide();
    });
  }

  private handleEditUserRole(email: string, role: string) {
    this.editingUserRole = email;
    this.editUserRole({ email, role }).finally(() => {
      this.editingUserRole = '';
    });
  }

  private setSortColumn(columnName: string) {
    if (this.sortColumn === columnName) {
      if (this.sortColumn.includes('-')) {
        this.sortColumn = columnName;
      } else {
        this.sortColumn = '-' + columnName;
      }
    } else {
      this.sortColumn = columnName;
    }
  }

  private getSortIcon(columnName: string) {
    if (this.sortColumn.includes(columnName)) {
      return this.sortColumn.includes('-') ? 'sort-up' : 'sort-down';
    }
    return 'sort';
  }

  get filteredSubscriptionUsers() {
    return this.subscriptionUsers.filter((user) => user.email !== this.userInfo.email).sort(
      (a: any, b: any) =>
        (this.sortColumn.includes('-') ? -1 : 1) *
        (a[this.sortColumn.replace('-', '')] > b[this.sortColumn.replace('-', '')] ? 1 : -1),
    );
  }

}
