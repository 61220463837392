import { render, staticRenderFns } from "./VAddUsers.vue?vue&type=template&id=6b78e351&scoped=true&"
import script from "./VAddUsers.vue?vue&type=script&lang=ts&"
export * from "./VAddUsers.vue?vue&type=script&lang=ts&"
import style0 from "./VAddUsers.vue?vue&type=style&index=0&id=6b78e351&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b78e351",
  null
  
)

export default component.exports